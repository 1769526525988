import React from 'react'

import { Table, Header, HeaderCell, Cell, Row } from '../common/Table'
import { SoftwareChargeType } from '../../lib/softwareChargeTypes'

interface Props {
  softwarePrices: {
    amount: any
    description: SoftwareChargeType | undefined
  }[]
}

export default function SoftwareServicesTable(props: Props) {
  if (!props.softwarePrices.length) return null

  return (
    <div className="width-500px mt-4">
      <p className="bold text--gray7 text-m m-0">Software Services</p>

      <Table>
        <thead>
          <tr>
            <HeaderCell>
              <Header>Description</Header>
            </HeaderCell>

            <HeaderCell>
              <Header>Unit Price</Header>
            </HeaderCell>
          </tr>
        </thead>
        <tbody>
          {props.softwarePrices.map(({ amount, description }) => (
            <Row key={description}>
              <Cell>{description}</Cell>
              <Cell>${amount}</Cell>
            </Row>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
