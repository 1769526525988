import React, { useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import { Modality } from '../../lib/modalityHelpers'
import { StatTypes_stat_types } from '../../hasura/graphQlQueries/types/StatTypes'
import { createPriceGroupAction, pricingSelector, PricingState } from '../../hasura/slices/pricing'
import { statTypeDescription } from '../../lib/pricingHelpers'
import { usersSelector, UsersState } from '../../hasura/slices/users'

// @ts-ignore
import closeIcon from '../../lib/images/close.png'

enum CreatePriceGroupStep {
  SelectModalities,
  SelectStatTypes,
}

interface Props {
  close: () => void
}

const titleForStep = (step: CreatePriceGroupStep) => {
  switch (step) {
    case CreatePriceGroupStep.SelectModalities:
      return 'Select Modalities'
    case CreatePriceGroupStep.SelectStatTypes:
      return 'Select STAT Turnaround Times'
  }
}

const modalities = [Modality.Xray, Modality.Ultrasound, Modality.Catscan]
const steps = [CreatePriceGroupStep.SelectModalities, CreatePriceGroupStep.SelectStatTypes]

export default function CreatePriceGroupModal(props: Props) {
  const dispatch = useDispatch()

  const { accessToken, user }: UsersState = useSelector(usersSelector)
  const { statTypes }: PricingState = useSelector(pricingSelector)

  const [step, setStep] = useState<CreatePriceGroupStep>(CreatePriceGroupStep.SelectModalities)
  const [selectedModalities, setSelectedModalities] = useState<Modality[]>([])
  const [selectedStatTypes, setSelectedStatTypes] = useState<StatTypes_stat_types[]>([])

  const nextStep = async () => {
    if (step === CreatePriceGroupStep.SelectModalities) {
      setStep(CreatePriceGroupStep.SelectStatTypes)
    } else {
      await dispatch(createPriceGroupAction(accessToken, user!, selectedModalities, selectedStatTypes))
      props.close()
    }
  }

  return (
    <Modal className="" fade={false} centered isOpen toggle={props.close}>
      <ModalHeader className="text-center flex-center w-100">
        Create Price Group
        <span className="d-block text-xs regular text--gray7">Step {steps.indexOf(step) + 1} of 2</span>
        <img
          onClick={() => dispatch(props.close)}
          className="pointer position-absolute icon-m m-1"
          src={closeIcon}
          style={{ top: '10px', right: '10px' }}
        />
      </ModalHeader>

      <ModalBody className="text-center text-m p-5">
        <p className="mb-1 semibold">{titleForStep(step)}:</p>

        <div className="mb-5 d-flex justify-content-center">
          {step === CreatePriceGroupStep.SelectModalities && (
            <div className="width-fit-content">
              {modalities.map((modality) => {
                const isSelected = selectedModalities.includes(modality)
                return (
                  <div className="d-flex align-items-center mt-1" key={modality}>
                    <input
                      onChange={() =>
                        setSelectedModalities(
                          isSelected ? selectedModalities.filter((m) => m !== modality) : [...selectedModalities, modality]
                        )
                      }
                      type="checkbox"
                      id={modality}
                      checked={isSelected}
                    />
                    <p className="mb-0 ml-1">{modality}</p>
                  </div>
                )
              })}
            </div>
          )}

          {step === CreatePriceGroupStep.SelectStatTypes && (
            <div className="width-fit-content">
              {statTypes.map((statType) => {
                const isSelected = selectedStatTypes.map((s) => s.id).includes(statType.id)
                return (
                  <div
                    onClick={() =>
                      setSelectedStatTypes(
                        isSelected ? selectedStatTypes.filter((s) => s.id !== statType.id) : [...selectedStatTypes, statType]
                      )
                    }
                    className="d-flex align-items-center pointer mt-1"
                    key={statType.id}
                  >
                    <input type="checkbox" id={`stat-${statType.id}`} checked={isSelected} />
                    <p className="mb-0 ml-1">{statTypeDescription(48, statType)}</p>
                  </div>
                )
              })}
            </div>
          )}
        </div>

        <Button onClick={nextStep} disabled={!selectedModalities.length} className="width-200px" color="primary">
          Continue
        </Button>
      </ModalBody>
    </Modal>
  )
}
