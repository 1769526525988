import React from 'react'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'

// @ts-ignore
import dots from '../../lib/images/dots.svg'

interface Props {
  deletePriceGroup: () => void
}

export default function PriceGroupMenu(props: Props) {
  return (
    <UncontrolledDropdown
      className="dropdown-menu-dots d-flex justify-content-end flex-even"
      onClick={(e: any) => e.stopPropagation()}
    >
      <DropdownToggle caret>
        <img className="icon-s ml-2 mb-1" src={dots} />
      </DropdownToggle>

      <DropdownMenu right>
        <DropdownItem header>
          <p className="m-0 text-s text--gray8">Price group actions</p>
        </DropdownItem>

        <DropdownItem text>
          <p onClick={props.deletePriceGroup} className="mb-2 hover-underline text-s regular pointer">
            Delete
          </p>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}
